// SASS variable for media query breakpoint
$breakpoint-desktop: 992px;

body, html, #root {
  font-family: 'Josefin Sans', sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgb(113, 67, 106);

}

a:link, a:visited {
  color:  rgb(113, 67, 106);
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

// Resetting default styles
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
}

// Carousel slides
.carousel__slide {
  display: none;
  list-style-type: none;
  text-align: center;

  @media (max-width: 991px) {
    //padding-right: 60px;
    //padding-left: 60px;
  }

  &--active {
    display: block;
  }
}
//backgorund imgages
.background__image1 {
  background-image: url(../Images/img1.png);
}

.background__image2 {
  background-image: url(../Images/img2.png);
  background-size: contain !important;
}

.background__image3 {
  background-image: url(../Images/img3.png);
}

//wierd name that is just a style to cover the screen
.App-header {
  background-color: white;
  min-height: 100vh;
  //min-width: 100vh;
  display: flex;
  justify-content: center;
  font-size: calc(50px + 5vmin);
  color: #6d3d55;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 991px) {
    background-size: contain;
  }
  
}

//header where the github icon is nested
.header {
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #ebb2d1, 20%, transparent);
  font-size: calc(20px + 4vmin);
  width: 100%;
  height: 20vh;
}


// Carousel arrows
.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #000000;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  font-size: calc(20px + 4vmin);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .5;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  @media (max-width: 991px) {
    top: 5%;
  }
}

